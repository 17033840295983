import { Loader } from '@mantine/core';
import { useValidatePhone } from 'api-hooks/common';
import colors from 'common/styles/colors';
import { ReplaceKeys } from 'common/utils/types';
import Button from 'components/elements/button';
import { BottomSheetRemote } from 'components/widgets/bottom-sheet';
import { useDebouncedEffect } from 'hooks/debounce';
import { useEffectLimits } from 'hooks/use-effect-derivatives';
import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import structuralStyles from 'styles/layout.css';

import CountrySelectBottomSheet from './bottom-sheet';
import { FormContext, useFormState } from '../../elements/form/context';
import Text from '../../elements/text/base';
import {
  NumericTextInput,
  TextInputProps,
} from '../../elements/text-input/base';

type PhoneNumberInputProps = ReplaceKeys<
  TextInputProps,
  {
    onChange?(phone: string): void;
    onValidate?(err: string | undefined): void;
    editPrefix?: boolean;
    initialPrefix?: string;
  }
>;

const PhoneNumberInput = React.forwardRef<
  HTMLInputElement,
  PhoneNumberInputProps
>(function (props, ref) {
  const {
    onChange,
    onValidate,
    value,
    label,
    noMargin,
    required,
    initialPrefix,
    editPrefix = false,
    error,
    ...restProps
  } = props;
  const controlledRawValue = props.value
    ? props.value.toString().split(' ', 2)
    : [];
  const controlledPrefix =
    controlledRawValue.length >= 2
      ? controlledRawValue[0]
      : controlledRawValue[0] || undefined;
  const controlledPhone =
    controlledRawValue.length >= 2
      ? controlledRawValue[1]
      : controlledRawValue[0];
  const [prefix, setPrefix] = React.useState(
    controlledPrefix ?? initialPrefix ?? '+62',
  );
  const [phone, setPhone] = React.useState(controlledPhone ?? '');
  const remote = React.useRef<BottomSheetRemote | null>(null);

  const { editable } = useFormState();

  React.useEffect(() => {
    if (controlledPrefix) {
      setPrefix(controlledPrefix);
    }
  }, [controlledPrefix]);

  React.useEffect(() => {
    setPhone(controlledPhone ?? '');
  }, [controlledPhone]);

  const fullPhone = !phone ? '' : [prefix ?? '', phone ?? ''].join(' ');
  const canSetPhone = useEffectLimits({
    times: 1,
    key: fullPhone,
    enabled: !!fullPhone,
  });
  React.useEffect(() => {
    if (!canSetPhone()) return;
    onChange?.(fullPhone);
  }, [canSetPhone, fullPhone, onChange]);

  const {
    mutate: validatePhone,
    isLoading: isValidatingPhone,
    error: phoneValidateError,
    reset: resetPhoneValidation,
  } = useValidatePhone();

  React.useEffect(() => {
    if (!phone) {
      resetPhoneValidation();
    }
  }, [phone, resetPhoneValidation]);

  React.useEffect(() => {
    if (isValidatingPhone) return;
    onValidate?.(phoneValidateError?.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneValidateError, isValidatingPhone]);

  const hasIgnoredMountEffect = React.useRef(false);
  useDebouncedEffect(
    () => {
      if (!hasIgnoredMountEffect.current) {
        hasIgnoredMountEffect.current = true;
        return;
      }
      if (!phone) {
        onChange?.('');
        return;
      }
      validatePhone({
        phoneNumber: `${prefix} ${phone}`,
      });
    },
    [prefix, phone],
    800,
  );

  return (
    <div>
      <Text textVariant="body1Regular">
        {label}
        {!!required && (
          <Text span textColor="backgroundNotification">
            &nbsp;*
          </Text>
        )}
      </Text>
      <div className={structuralStyles.flexbox({ gap: 8, align: 'start' })}>
        <Button
          variant={{
            variant: 'secondary',
          }}
          disabled={!editable && !editPrefix}
          style={
            !editable && !editPrefix
              ? {
                  backgroundColor: colors.backgroundScreen,
                  color: colors.foregroundTertiary,
                }
              : undefined
          }
          onClick={() => remote.current?.open()}
          miw={80}
          h={42}
        >
          {prefix}
        </Button>
        <NumericTextInput
          value={phone}
          ref={ref}
          onChange={(e) => setPhone(e.target.value.replaceAll(/[^0-9]+/g, ''))}
          error={error ?? phoneValidateError?.message}
          {...restProps}
          w="100%"
          rightSection={isValidatingPhone ? <Loader size="xs" /> : undefined}
          noMargin={noMargin}
        />
      </div>
      <CountrySelectBottomSheet
        onChange={(country) => setPrefix(country.dialCode)}
        trailingType="dialCode"
        ref={remote}
      />
    </div>
  );
});

export default PhoneNumberInput;

export interface PhoneNumberInputFieldProps extends TextInputProps {
  name: string;
  type: 'tel';
  onAfterChange?(value: string): void;
}

export function PhoneNumberInputField(props: PhoneNumberInputFieldProps) {
  const {
    name,
    disabled: _disabled,
    readOnly,
    type,
    required,
    onAfterChange,
    ...rest
  } = props;

  const context = React.useContext(FormContext);
  const { control, setError, clearErrors } = useFormContext<any>();
  const {
    field: { onChange, ...restField },
    fieldState,
  } = useController({ name, control });

  const disabled = !context.editable || readOnly || _disabled;
  const error = fieldState?.error?.message;

  return (
    <PhoneNumberInput
      type="tel"
      {...rest}
      {...restField}
      {...(!disabled && { required })}
      onValidate={(err) => {
        if (err) {
          setError(name, {
            message: err,
            type: 'manual',
          });
        } else if (fieldState.error?.type === 'manual') {
          clearErrors(name);
        }
      }}
      error={error}
      disabled={disabled}
      onChange={(e) => {
        onChange(e);
        onAfterChange?.(e);
      }}
    />
  );
}
