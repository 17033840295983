import { FileResultModel, IdInput, getParamsInput } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';
import { NotificationTypeEnum } from 'common/constants/enum';
import { selectLanguage } from 'hooks/use-language';

// Model
export class NotificationLiteModel {
  id: string;
  type: NotificationTypeEnum;
  title: string;
  description: string;
  redirect: string;

  @Expose({ name: 'read_at' })
  @Type(() => Date)
  readAt: Date | null;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;
}

export class NotificationModel extends NotificationLiteModel {}

export class BadgeCountModel {
  @Type(() => Number)
  count: number;
}

export class BadgesModel {
  @Type(() => BadgeCountModel)
  cart: BadgeCountModel;

  @Type(() => BadgeCountModel)
  notification: BadgeCountModel;
}

export class BannerModel {
  id: string;

  @Expose({ name: 'start_at' })
  @Type(() => Date)
  startAt: Date;

  @Expose({ name: 'end_at' })
  @Type(() => Date)
  endAt: Date;

  @Expose({ name: 'alt_text' })
  altText: string;

  url: string | null;

  get photo(): FileResultModel {
    return selectLanguage({
      en: this.enPhoto,
      id: this.idPhoto,
    });
  }

  @Expose({ name: 'en_photo' })
  @Type(() => FileResultModel)
  enPhoto: FileResultModel;

  @Expose({ name: 'id_photo' })
  @Type(() => FileResultModel)
  idPhoto: FileResultModel;
}

// Input

export type getNotificationsInput = getParamsInput;

export type getNotificationInput = IdInput;

export type ReadNotificationInput = {
  notificationId: string;
};
