import { useElementSize } from '@mantine/hooks';
import React from 'react';

interface AppLayoutSizeContextType {
  header: {
    width: number;
    height: number;
  };
  footer: {
    width: number;
    height: number;
  };
}

export const AppLayoutSizeContext =
  React.createContext<AppLayoutSizeContextType>({
    header: {
      width: 0,
      height: 0,
    },
    footer: {
      width: 0,
      height: 0,
    },
  });

export function useSetupAppLayoutSizeContext() {
  const header = useElementSize();
  const footer = useElementSize();
  return {
    headerRef: header.ref,
    footerRef: footer.ref,
    context: React.useMemo(
      () => ({
        header: {
          width: header.width,
          height: header.height,
        },
        footer: {
          width: footer.width,
          height: footer.height,
        },
      }),
      [footer.height, footer.width, header.height, header.width],
    ),
  };
}
