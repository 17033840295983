import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';

import useKurosimNavigation, {
  StackNavigation,
  TabNavigation,
} from './use-kurosim-navigation';
import useGetParentRef, { ParentRefType } from './use-parent-ref';

export default function useKurosimParentScroll() {
  const ref = useGetParentRef(ParentRefType.Scroll);
  const { currentRoute } = useKurosimNavigation();
  const pathname = currentRoute()?.pathname;

  const { isReady } = useRouter();

  //bind scroll
  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!isReady) return;

    const element = ref.current;

    if (!element) return;

    const onScroll = debounce(() => {
      const current = currentRoute();
      TabNavigation.update(pathname, {
        ...current,
        scrollY: element.scrollTop,
      });
      StackNavigation.update(pathname, {
        ...current,
        scrollY: element.scrollTop,
      });
    }, 25);

    // tab first
    const tabScrollY = TabNavigation.get().find((tab) => {
      const result = tab.pathname === pathname;
      return result;
    })?.scrollY;

    element.addEventListener('scroll', onScroll);

    if (typeof tabScrollY === 'number') {
      element.scroll({ top: tabScrollY });
      return () => {
        element.removeEventListener('scroll', onScroll);
      };
    }

    // if tab not found use stack
    const stackScrollY = StackNavigation.get().find((stack) => {
      const result = stack.pathname.includes(pathname);
      return result;
    })?.scrollY;

    if (typeof stackScrollY === 'number') {
      element.scroll({ top: stackScrollY });
      return () => {
        element.removeEventListener('scroll', onScroll);
      };
    }
  }, [currentRoute, isReady, pathname, ref]);

  return ref;
}
